import * as React from "react";
import { useMemo } from "react";
import { InputConnector, InputConnectorsApi } from "../../client";
import { Link } from "react-router-dom";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Popconfirm, Table } from "antd";
import { ITableProps, useApiModels } from "../../shared/hooks/useApiModels";
import moment from "moment/moment";
import { DATE_TIME_FORMAT } from "../../shared/constants";
import { Status } from "../../shared/components/Status";
import { diffBetweenMomentsAsString } from "../../shared/functions";
import { InputRecordWithAdditionalData } from "../hooks/useInputRecords";

interface IProps {
    inputRecords: InputRecordWithAdditionalData[];
    loading: boolean;
    tableProps: ITableProps<InputRecordWithAdditionalData>;
    onDelete: (inputRecordId: number) => void;
}

export const InputRecordTable: React.FC<IProps> = (props) => {
    const inputConnectorIds: string[] = useMemo(() => {
        return Array.from(
            new Set(
                props.inputRecords.map((inputRecord) =>
                    String(inputRecord.input_connector_id!)
                )
            )
        );
    }, [props.inputRecords]);

    const { models: inputConnectors } = useApiModels<InputConnector>(
        (page, filters) =>
            new InputConnectorsApi().listInputConnectors({
                page,
                ids: inputConnectorIds.join(",") || "0",
                ...filters,
            }),
        "generic",
        [props.inputRecords]
    );

    const inputConnectorsById: { [key: number]: InputConnector } =
        useMemo(() => {
            return inputConnectors.reduce(
                (prev, inputConnector) => ({
                    ...prev,
                    [inputConnector.id]: inputConnector,
                }),
                {}
            );
        }, [inputConnectors]);

    return (
        <Table<InputRecordWithAdditionalData>
            title={() =>
                `${props.inputRecords.length}/${props.tableProps.pagination.total}`
            }
            dataSource={props.inputRecords}
            loading={props.loading}
            rowKey="id"
            {...props.tableProps}
            columns={[
                {
                    key: "id",
                    dataIndex: "id",
                    width: "20px",
                    render: (id) => (
                        <Link to={`/input-records/${id}`}>
                            <EyeOutlined style={{ color: "black" }} />
                        </Link>
                    ),
                },
                {
                    key: "is_converted",
                    dataIndex: "is_converted",
                    title: "Converted",
                    width: "100px",
                    render: (converted) => <Status value={converted} />,
                    filterMultiple: false,
                    filters: [
                        {
                            text: "Yes",
                            value: true,
                        },
                        {
                            text: "No",
                            value: false,
                        },
                    ],
                },
                {
                    key: "is_exported",
                    dataIndex: "is_exported",
                    title: "Exported",
                    width: "100px",
                    render: (exported) => <Status value={exported} />,
                    filterMultiple: false,
                    filters: [
                        {
                            text: "Yes",
                            value: true,
                        },
                        {
                            text: "No",
                            value: false,
                        },
                    ],
                },
                {
                    key: "is_skipped",
                    dataIndex: "is_skipped",
                    title: "Skipped",
                    width: "100px",
                    render: (skipped) => <Status value={skipped} />,
                    filterMultiple: false,
                    filters: [
                        {
                            text: "Yes",
                            value: true,
                        },
                        {
                            text: "No",
                            value: false,
                        },
                    ],
                },
                {
                    key: "created",
                    dataIndex: "created",
                    title: "Created",
                    width: "200px",
                    render: (created) =>
                        moment(created).format(DATE_TIME_FORMAT),
                },
                {
                    key: "exported_on",
                    dataIndex: "exported_on",
                    title: "Exported on",
                    width: "200px",
                    render: (_, record) =>
                        record.exported_on
                            ? moment(record.exported_on).format(
                                  DATE_TIME_FORMAT
                              )
                            : "-",
                },
                {
                    key: "export_time",
                    dataIndex: "export_time",
                    title: "Time to export (mm:ss:fff)",
                    width: "100px",
                    render: (_, record) =>
                        record.exported_on
                            ? diffBetweenMomentsAsString(
                                  moment(record.created),
                                  moment(record.exported_on)
                              )
                            : "-",
                },
                {
                    key: "amount_of_rows",
                    dataIndex: "amount_of_rows",
                    title: "Rows",
                    width: "100px",
                },
                {
                    key: "input_connector_id",
                    dataIndex: "input_connector_id",
                    title: "Input connector",
                    width: "250px",
                    render: (inputConnectorId) =>
                        inputConnectorsById[inputConnectorId] ? (
                            <Link to={`/inputs/${inputConnectorId}`}>
                                {inputConnectorsById[inputConnectorId].name}
                            </Link>
                        ) : (
                            "-"
                        ),
                },
                {
                    key: "file",
                    dataIndex: "file",
                    title: "File",
                    render: (_, record) => (
                        <Link
                            to={`/api/input_records/${record.id}/download/`}
                            target="_blank"
                        >
                            {record.file_name}
                        </Link>
                    ),
                },
                {
                    key: "delete",
                    dataIndex: "id",
                    width: "20px",
                    render: (id) => (
                        <Popconfirm
                            title="Are you sure you want to delete this file?"
                            onConfirm={() => props.onDelete(id)}
                        >
                            <DeleteOutlined style={{ color: "black" }} />
                        </Popconfirm>
                    ),
                },
            ]}
        />
    );
};
