import React from "react";
import { BaseLayout } from "../../shared/components/BaseLayout";
import { InputConnector, InputConnectorsApi } from "../../client";
import { Modal, Table, Tooltip } from "antd";
import { useApiModels } from "../../shared/hooks/useApiModels";
import {
    CommentOutlined,
    EyeOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { InputConnectorLogModal } from "../components/InputConnectorLogModal";
import { Status } from "../../shared/components/Status";
import { InputConnectorDocumentationModal } from "../components/InputConnectorDocumentationModal";

interface IProps {}

export const InputConnectorOverview: React.FC<IProps> = (props) => {
    const [inputConnectorId, setInputConnectorId] = React.useState<
        number | null
    >(null);
    const [docsInputConnectorId, setDocsInputConnectorId] = React.useState<
        number | null
    >(null);

    const {
        models: inputConnectors,
        loading,
        tableProps,
        fetch,
    } = useApiModels(
        (page) => new InputConnectorsApi().listInputConnectors({ page }),
        "generic"
    );

    const setActive = (id: number, newActive: boolean) => {
        Modal.confirm({
            title: "Are you sure you want to make the InputConnector active/inactive?",
            onOk: () =>
                new InputConnectorsApi()
                    .partialUpdateInputConnector({
                        id: String(id),
                        partialInputConnector: {
                            active: newActive,
                        },
                    })
                    .then((res) => {
                        fetch();
                        return res;
                    }),
        });
    };

    return (
        <BaseLayout>
            {inputConnectorId && (
                <InputConnectorLogModal
                    inputConnectorId={inputConnectorId}
                    onClose={() => setInputConnectorId(null)}
                />
            )}
            {docsInputConnectorId && (
                <InputConnectorDocumentationModal
                    inputConnectorId={docsInputConnectorId}
                    onClose={() => setDocsInputConnectorId(null)}
                />
            )}
            <Table<InputConnector>
                dataSource={inputConnectors}
                loading={loading}
                rowKey="id"
                {...tableProps}
                columns={[
                    {
                        key: "id",
                        dataIndex: "id",
                        width: "20px",
                        render: (id) => (
                            <Link to={`/inputs/${id}`}>
                                <EyeOutlined style={{ color: "black" }} />
                            </Link>
                        ),
                    },
                    {
                        key: "id",
                        dataIndex: "id",
                        width: "20px",
                        render: (id, record) => (
                            <Tooltip title="View documentation">
                                <CommentOutlined
                                    style={{ color: "black" }}
                                    onClick={() => setDocsInputConnectorId(id)}
                                />
                            </Tooltip>
                        ),
                    },
                    {
                        key: "id",
                        dataIndex: "id",
                        width: "20px",
                        render: (id, record) =>
                            record.type === "sftp" && (
                                <Tooltip title="View logs">
                                    <UnorderedListOutlined
                                        style={{ color: "black" }}
                                        onClick={() => setInputConnectorId(id)}
                                    />
                                </Tooltip>
                            ),
                    },
                    {
                        key: "name",
                        dataIndex: "name",
                        title: "Name",
                    },
                    {
                        key: "active",
                        dataIndex: "active",
                        title: "Active",
                        render: (isActive, record) => (
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => setActive(record.id, !isActive)}
                            >
                                <Status value={isActive} />
                            </div>
                        ),
                    },
                    {
                        key: "type",
                        dataIndex: "type",
                        title: "Type",
                    },
                    {
                        key: "converter_type",
                        dataIndex: "converter_type",
                        title: "Converter type",
                    },
                    {
                        key: "check_every_x_minutes",
                        dataIndex: "check_every_x_minutes",
                        title: "Check every x minutes",
                        render: (minutes) =>
                            minutes > 0 ? `${minutes} min` : "-",
                    },
                    {
                        key: "endpoint",
                        dataIndex: "endpoint",
                        title: "Endpoint",
                    },
                    {
                        key: "is_success",
                        dataIndex: "is_success",
                        title: "Last check successful",
                        render: (success) => <Status value={success} />,
                    },
                ]}
            />
        </BaseLayout>
    );
};
